/*!
 * Color Admin v4.6.0 (https://seantheme.com/color-admin/)
 * Copyright 2020 SeanTheme
 */

@import 'functions';
@import 'variables';
@import 'node_modules/bootstrap/scss/bootstrap';
@import 'mixins';
@import 'reboot';
@import 'app';
@import 'layout';
@import 'ui';
@import 'plugins';
@import 'helper';
@import 'react';

#dualselect-selected option {
  font-weight: 600 !important;
}

.rdl-control-label {
  font-weight: 600 !important;
}

.no-padding-horizontal {
  padding-left: unset !important;
  padding-right: unset !important;
}

.text-align-left {
  text-align: left;
}

.text-align-center {
  text-align: center;
}

.filter-icons {
  margin-left: 16px;
  cursor: pointer;
  opacity: 0.9;
  transition: 0.2s;
}

.filter-icons:hover {
  color: #f79c20;
  opacity: 1.0;
}

.date-range {
  width: 100%;
}

.cursor-pointer {
  cursor: pointer;
}

.react-datepicker-popper{
  z-index: 2;
}

.select-input-range{
  width: 65%;
  margin-left: auto;
}

.select-input-Stock-balance-comparison{
  width: 65%;
}

.margin-Stock-balance-comparison{
  margin-right: 2px !important;
  margin-left: 2px !important;
}

@media (max-width: 1200px)
{
  .select-input-range{
    width: 100%;
    margin-left: auto;
   }

   .select-input-Stock-balance-comparison{
    width: 100%;
  }

  .margin-top-stock-balance-comparison{
    margin-top: 10px; 
  }
}

.add_display_flex .react-datepicker-wrapper{
  display: flex;
}
.adjustmentPadding .modal-body {
  padding: 0px 1rem !important;
}