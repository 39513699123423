@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --red: #dc3545;
  --grey: #9e9e9e;
  --white: #eaeaea;
  --green: #28a745;
  // --yellow: #d39f00;
  --yellow: #edc900;
  --background: #2b2c30;
}

body {
  color: var(--white);
  background: var(--background);
}

a {
  text-decoration: none;
  color: inherit;
}

ol,
ul {
  list-style: none;
}

body,
button,
input,
select,
textarea {
  font-family: 'Inter', sans-serif;
}

button {
  border: 0;
  background: none;
  color: inherit;
  font: inherit;
}

@media (max-width: 768px) {
  .gZzsDe {
    display: flex !important;
    div {
      margin: 0 5px;
      padding: 0;
    }
  }
}
