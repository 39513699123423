.listTasks {
  gap: 3rem;
  display: flex;
  margin: 4rem 0;
  flex-wrap: wrap;
  padding: 0 2.5rem;
  @media (max-width: 768px) {
    gap: 1.5rem;
    margin: 2rem 0;
  }
}
